import axios from "axios";
export const createManualJournal = async (payload) => {
  return await axios.post(`/journal/create`, payload);
 
};

export const getAllJournals = async (params) => {
  try {
    return await axios.get(`/journal/all`, { params });
  } catch (error) {
    return error;
  }
};

export const updateJournals = async (id, payload) => {
  return await axios.put(`/journal/update/${id}`, payload);
};

export const deleteJournals = async (id) => {
  try {
    return await axios.delete(`/journal/delete/${id}`);
  } catch (error) {
    return error;
  }
};

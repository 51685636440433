<template>
  <b-modal
    id="create-journal-modal"
    ref="CreateJournalModal"
    title="Create Journal"
    size="lg"
    header-class="font-primary"
    header-bg-variant="light"
    header-text-variant="main-green"
    header-border-variant="white"
    footer-bg-variant="transparent"
    footer-border-variant="white"
    no-close-on-esc
    no-close-on-backdrop
    @hide="closeModal()"
  >
    <template v-slot:modal-title>
      <template v-if="!publish_prop">
        <!-- is_view -->
        <span v-if="is_view" class="ml-4 text-main-green">{{ is_draft ?'View Draft':'View Journals' }}</span>
        <span v-if="is_edit" class="ml-4 text-main-green">{{ is_draft ?'Update Draft':'Update Journal' }}  </span>
        <span v-if="!is_edit && !is_view" class="ml-4 text-main-green"
          >Create Journal</span
        >
      </template>
      <template v-else>
        <span  class="ml-4 text-main-green">Publish Draft</span>
      </template>
    </template>
    <template v-slot:modal-footer="{ cancel }">
      <!-- Emulate built in modal footer ok and cancel button actions -->
      <div
        class="d-flex flex-column flex-sm-row align-items-end justify-content-end mx-0 mb-3 w-100"
      >
        <b-button
          variant="gray-550"
          pill
          class="my-2 my-sm-0 mr-0 mr-sm-2 text-white border-0 bg-gray-550 action-btn"
          style="width: 110px;"
          @click="cancel"
          v-if="!is_view"
        >
          Cancel
        </b-button>
        <b-button
          v-if="!is_edit && !is_view"
          variant="gray-550"
          :disabled="isLoading"
          pill
          class="my-2 my-sm-0 mr-0 mr-sm-2 text-main-green border-1 border-main-green bg-white action-btn"
          @click="markAsDraft()"
        >
          Save as a draft
        </b-button>
        <b-button
          :disabled="isLoading"
          variant="main-green"
          pill
          class="my-2 my-sm-0 ml-0 border-0 action-btn"
          style="width: 110px;"
          @click="createJournal()"
          v-if="!is_view  &&!publish_prop"
        >
          {{ is_edit ? "Save" : "Publish" }}
        </b-button>
        <b-button
          :disabled="isLoading"
          variant="main-green"
          pill
          class="my-2 my-sm-0 ml-0 border-0 action-btn"
          style="width: 110px;"
          @click="createJournal()"
          v-if="publish_prop"
        >
          Published
        </b-button>
      </div>
    </template>
    <!-- custom close icon -->
    <template v-slot:modal-header-close>
      <b-icon
        icon="x-circle"
        class="text-dark"
        @click="closeModal()"
        aria-label="Close Modal"
        font-scale="0.8"
      ></b-icon>
    </template>
    <b-row
      class="d-flex flex-row align-items-start justify-content-start w-100 py-1 mb-3 mb-md-2"
      :class="'mt-3'"
      no-gutters
    >
    <b-row
        class="d-flex flex-row align-items-start justify-content-start w-100 pb-2 px-3 mb-3"
        no-gutters
      >
      <b-col class="mr-md-4" cols="6">
          <b-input-group>
            <b-form-input
              id="example-input"
              :value="dateFormat(createdDate)"
              :state="createdDateError.status"
              :invalidFeedback="`${createdDateError.message}`"
              type="text"
              class="date-fields-style date-picker  "
              :class="
                createdDateError.status != false
                  ? 'border-main-green'
                  : 'border-danger'
              "
              style="height:40px;"
              placeholder="Date"
              autocomplete="off"
              :disabled="is_view"
            ></b-form-input>
            <b-input-group-append>
              <b-form-datepicker
                class="date-picker-btn font-primary datepicker align-items-center"
                v-model="createdDate"
                :disabled="is_view"
                no-flip
                size="sm"
                button-only
                button-variant="white"
                locale="en-US"
                right
                nav-button-variant="main-green"
                tohour-variant="main-green"
                menu-class=" rounded-lg custom-calendar-dropdown text-prime-gray"
                aria-controls="start-date-input"
              ></b-form-datepicker>
            </b-input-group-append>
          </b-input-group>
        </b-col>
    </b-row>
      <b-row
        class="d-flex flex-row align-items-start justify-content-center w-100 pb-2 px-3"
        no-gutters
      >
        <b-col>
          <FormInput
            groupId="particular-name-group"
            id="particular-name-group-input"
            class="text-prime-gray rounded-2 pr-0"
            label="Reference Number"
            v-model="referenceNumber"
            :state="referenceNumberError.status"
            :invalidFeedback="`${referenceNumberError.message}`"
            :whiteBG="false"
            trim
            form="login-form"
            type="text"
            placeholder="#001"
            :isDisabled="is_view"
          ></FormInput>
        </b-col>
        <b-col class="ml-md-4" v-if="isRecurringJournal">
          <b-input-group>
            <b-form-input
              id="example-input"
              :value="dateFormat(startDate)"
              :state="startDateError.status"
              :invalidFeedback="`${startDateError.message}`"
              type="text"
              class="date-fields-style date-picker  "
              :class="
                startDateError.status != false
                  ? 'border-main-green'
                  : 'border-danger'
              "
              style="height:40px;"
              placeholder="Recurring Start Date"
              autocomplete="off"
              :disabled="is_view"
            ></b-form-input>
            <b-input-group-append>
              <b-form-datepicker
                class="date-picker-btn font-primary datepicker align-items-center"
                v-model="startDate"
                :disabled="is_view"
                no-flip
                size="sm"
                button-only
                button-variant="white"
                locale="en-US"
                right
                nav-button-variant="main-green"
                tohour-variant="main-green"
                menu-class=" rounded-lg custom-calendar-dropdown text-prime-gray"
                aria-controls="start-date-input"
              ></b-form-datepicker>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row
        class="d-flex flex-row align-items-start justify-content-center w-100 pb-2 px-3"
        no-gutters
      >
        <b-col class="pb-3">
          <b-form-checkbox
            id="checkbox-1"
            name="checkbox-1"
            v-model="isRecurringJournal"
            :value="true"
            :unchecked-value="false"
            style="height:40px"
            :disabled="is_view"
          >
            <span class="d-inline">Recurring Journal</span>
          </b-form-checkbox>
        </b-col>
        <b-col class="ml-md-4 pb-3">
          <b-dropdown
            id="dropdown-form"
            ref="dropdown"
            variant="light"
            class="w-100 m-0 py-0"
            toggle-class="border border-dark-subtle bg-white rounded-lg border border-primary"
            menu-class="w-100 bg-white border border-dark-subtle border-5 py-0 "
            left
            no-caret
            no-flip
            text="Button text via Prop"
            :disabled="is_view"
          >
            <template v-slot:button-content>
              <b-button
                block
                id="customer-modal-btn"
                variant="transparent"
                class="w-100 d-flex  align-items-center justify-content-between
               rounded-lg p-0 m-0"
              >
                <span class="text-primary">{{
                  recurring ? recurring : "Monthly"
                }}</span>
                <b-icon
                  icon="chevron-down"
                  class="text-primary"
                  font-scale="0.9"
                ></b-icon>
              </b-button>
            </template>
            <div style="max-height: 300px; overflow-y: scroll;">
              <b-dropdown-item
                @click="() => getRecurringOption(recurring)"
                href="#"
                v-for="(recurring, index) in recurringOptions"
                :key="index"
              >
                {{ recurring }}
              </b-dropdown-item>
            </div>
          </b-dropdown>
        </b-col>
      </b-row>
      <b-row
        class="d-flex flex-row align-items-start justify-content-center w-100 pb-2 px-3"
        no-gutters
      >
        <b-col class="pb-3" >
          <b-form-checkbox
            id="checkbox-2"
            name="checkbox-2"
            v-model="isRecurringJournalEnd"
            :value="true"
            :unchecked-value="false"
            style="height:40px"
            :disabled="is_view"
          >
            <span class="d-inline mx-2 pb-3">End Recurring</span>
          </b-form-checkbox>
        </b-col>
        <b-col class="ml-md-4 pb-3" v-if="isRecurringJournalEnd" v-b-tooltip.hover.right="'Please select a end date beginning from this month!'">
          <b-input-group>
            <b-form-input
              id="example-input"
              :value="dateFormat(endDate)"
              :state="endDateError.status"
              :invalidFeedback="`${endDateError.message}`"
              class="date-fields-style date-picker"
              :class="
                endDateError.status != false
                  ? 'border-main-green'
                  : 'border-danger'
              "
              style="height:40px"
              type="text"
              placeholder="End Date"
              autocomplete="off"
              :disabled="is_view"
            ></b-form-input>
            <b-input-group-append>
              <!-- :min="minEndDate" -->
              <b-form-datepicker
                id="Recurring End Date"
                class="date-picker-btn font-primary datepicker align-items-center"
                v-model="endDate"
                no-flip
                size="sm"
                :min="minDate"
                button-only
                button-variant="white"
                locale="en-US"
                right
                nav-button-variant="main-green"
                today-variant="main-green"
                menu-class=" rounded-lg custom-calendar-dropdown text-prime-gray"
                aria-controls="start-date-input"
                :disabled="is_view"
              ></b-form-datepicker>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row
        class="d-flex flex-row align-items-start justify-content-center w-100 pb-2 px-3"
        no-gutters
      >
        <b-col cols="12">
          <FormInput
            groupId="user-name-group"
            id="user-name-group-input"
            class="text-prime-gray rounded-2"
            v-model="journalDescription"
            :state="journalDescriptionError.status"
            :invalidFeedback="`${journalDescriptionError.message}`"
            label="Description"
            placeholder="Write a Description"
            isRequired
            :whiteBG="false"
            trim
            form="login-form"
            type="text"
            :isDisabled="is_view"
          ></FormInput>
        </b-col>
      </b-row>
      <hr style="width: 89%; text-align: center" />

      <!-- table row -->
      <b-row
        class="d-flex flex-row align-items-start justify-content-start px-3 w-100 table-container-row"
        no-gutters
      >
        <b-table
          ref="questionsTable"
          :bordered="false"
          :borderless="true"
          show-empty
          sticky-header="600px"
          select-mode="single"
          class="table-element"
          thead-class="table-header font-info"
          tbody-class="table-body"
          :fields="JournalHeader"
          :items="transactions"
        >
          <!-- table busy state -->
          <template #table-busy>
            <div class="text-center text-muted my-3">
              <b-spinner class="align-middle"></b-spinner>
              <span class="text-left mx-1">Loading...</span>
            </div>
          </template>
          <!-- table empty state -->
          <template #empty="scope">
            <b-row
              class="d-flex flex-row align-items-center justfiy-content-center py-5"
              no-gutters
            >
              <h6 class="text-center mb-0 mx-auto my-5">
                {{ scope.emptyText }}
              </h6>
            </b-row>
          </template>
          <!-- Date -->
          <template #cell(description)="data">
            <b-col
              class="d-flex flex-column align-items-start justify-content-center px-0"
            >
              <span class="main-text">{{ data.value }}</span>
            </b-col>
          </template>
          <!-- Reference -->
          <template #cell(glCode)="data">
            <b-col
              class="d-flex flex-column align-items-start justify-content-center px-0"
            >
              <span class="main-text mb-2">{{ data.value }}</span>
            </b-col>
          </template>
          <!-- Description  -->
          <template #cell(taxRate)="data">
            <b-col
              class="d-flex flex-column align-items-start justify-content-center px-0"
            >
              <span class="main-text mb-2">{{ data.value + "%" }}</span>
            </b-col>
          </template>
          <template #cell(debit)="data">
            <b-col
              class="d-flex flex-column align-items-start justify-content-center px-0"
            >
              <span class="main-text mb-2">{{ data.value }}</span>
            </b-col>
          </template>
          <template #cell(credit)="data">
            <b-col
              class="d-flex flex-column align-items-start justify-content-center px-0"
            >
              <span class="main-text mb-2">{{ data.value }}</span>
            </b-col>
          </template>
          <!-- actions column -->
          <template #cell(delete)="data" v-if="!is_view">
            <b-icon
              icon="x-circle"
              aria-label="Remove Item"
              class="delete-icon"
              @click="removeItem(data)"
            ></b-icon>
            <!-- </b-button> -->
          </template>
          <template #cell(edit)="data" v-if="!is_view">
            <b-icon
              icon="pencil-square"
              aria-label="Remove Item"
              class=" delete-icon text-main-green"
              @click="editItem(data)"
            ></b-icon>
            <!-- </b-button> -->
          </template>
        </b-table>
      </b-row>
      <template v-if="!is_view">
        <!-- Add One Transaction Row -->
        <b-row
          class="d-flex flex-row align-items-start justify-content-start w-100 px-3"
          no-gutters
        >
          <b-col cols="12" md="3" class="pr-2">
            <FormInput
              groupId="user-name-group"
              id="add-item-invoice-create"
              class="text-prime-gray rounded-2"
              label="Description"
              v-model="description"
              :whiteBG="false"
              :state="itemDescriptionError.status"
              :invalidFeedback="`${itemDescriptionError.message}`"
              trim
              form="login-form"
              type="text"
            ></FormInput>
          </b-col>
          <b-col cols="12" md="2" class="pr-2">
            <FormSelect
              groupId="user-glcode-group"
              id="user-glcode-input"
              class="text-prime-gray rounded-2"
              label="GL Code"
              v-model="glCode"
              :state="glCodeError.status"
              :invalidFeedback="`${glCodeError.message}`"
              :whiteBG="false"
              trim
              form="login-form"
              type="text"
              :options="glOptions" 
            ></FormSelect>
          </b-col>
          <b-col cols="12" md="2" class="pr-2">
            <FormInput
              groupId="user-Description-group"
              id="user-Description-group-input"
              class="text-prime-gray rounded-2"
              label="Tax Rate"
              v-model="taxRate"
              :state="taxRateError.status"
              :invalidFeedback="`${taxRateError.message}`"
              :whiteBG="false"
              trim
              form="login-form"
              type="number"
            ></FormInput>
          </b-col>
          <b-col cols="12" md="2" class="pr-2">
            <FormInput
              groupId="user-Quantity-group"
              id="user-Quantity-group-input"
              class="text-prime-gray rounded-2"
              label="Debit"
              v-model="debit"
              :isDisabled="debitDisable"
              :state="debitError.status"
              :invalidFeedback="`${debitError.message}`"
              :whiteBG="false"
              trim
              form="login-form"
              type="number"
            ></FormInput>
          </b-col>

          <b-col cols="12" md="2" class="pr-2">
            <FormInput
              groupId="user-Amount-group"
              id="user-Amount-group-input"
              class="text-prime-gray rounded-2"
              label="Credit"
              creditDisable
              v-model="credit"
              :isDisabled="creditDisable"
              :state="creditError.status"
              :invalidFeedback="`${creditError.message}`"
              :whiteBG="false"
              trim
              form="login-form"
              type="number"
            ></FormInput>
          </b-col>
          <b-col
            class="d-flex flex-column align-items-end justify-content-end"
            cols="12"
            md=""
          >
            <b-button
              variant="transparent"
              class="d-flex flex-row align-items-center justify-content-center mx-0 my-lg-0 px-2 pb-2 shadow-none text-main-green item-plus-btn"
              pill
              @click="addSingleTransaction"
            >
             
              <b-icon v-if="!isEditTransactionItem"
                class="text-main-green"
                icon=  "plus-circle"
                size="sm"
                aria-label="Plus"
                font-scale="1.8"
              ></b-icon>
              <b-icon v-else
              icon="pencil-square"
              aria-label="Remove Item"
              class=" delete-icon text-main-green"
            ></b-icon>
            </b-button>
          </b-col>
        </b-row>
      </template>
      <hr style="width: 89%; text-align: center" />
      <b-row
        class="d-flex flex-row align-items-start justify-content-start w-100 pb-2 px-3"
        no-gutters
      >
        <b-col cols="12">
          <b-row
            class="d-flex flex-row align-items-start justify-content-start w-100 "
            no-gutters
          >
            <b-col cols="12">
              <div class="d-flex px-4">
                <span class="main-text mr-auto"   :class="!balance ? 'text-red' : ''">Credit Total Amount</span>
                <div>
                  <span class="main-text ml-3 "   :class="!balance ? 'text-red' : ''"
                    >{{ currency }} {{ formatNumbers(creditSubTotal) }}</span
                  >
                </div>
              </div>
            </b-col>
            <b-col cols="12">
              <div class="d-flex px-4">
                <span class="main-text mr-auto"   :class="!balance ? 'text-red' : ''">Debit Total Amount</span>
                <div>
                  <span class="main-text "   :class="!balance ? 'text-red' : ''"
                    >{{ currency }} {{ formatNumbers(debitSubTotal)  }}</span
                  >
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row
            class="d-flex flex-row align-items-start justify-content-start w-100 "
            no-gutters
          >
            <b-col cols="12">
              <div class="d-flex px-4">
                <span class="main-text mr-auto">GST Total Amount</span>
                <div>
                  <span class="main-text ml-3 "
                    >{{ currency }} {{ formatNumbers(totalGstValue) }}</span
                  >
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row
            class="d-flex flex-row align-items-start justify-content-start w-100 "
            no-gutters
          >
            <b-col cols="12">
              <div class="d-flex px-4">
                <span
                  class="main-text mr-auto"
                  >Total Amount</span
                >
                <div>
                  <span class="main-text"
                    >{{ currency }} {{ formatNumbers( debitTotal )}}</span
                  >
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row
            class="d-flex flex-row align-items-start justify-content-start w-100 "
            no-gutters
            v-if="!balance"
          >
            <b-col cols="12" class="mt-3">
              <b-alert show variant="danger"
                >Total credit must equal to total debit</b-alert
              >
            </b-col>
          </b-row>
          <b-row
            class="d-flex flex-row align-items-start justify-content-start w-100 mt-4 "
            no-gutters
            v-if="!is_view || is_edit"
          >
            <b-col cols="12" class="mt-3">  
                <b-button variant="main-green"  
                @click="showNote=!showNote"   
               class="my-2 my-sm-0 ml-0 border-0">Add a Note</b-button>
            </b-col>
          </b-row>
          <b-row
            class="d-flex flex-row align-items-start justify-content-start w-100 "
            no-gutters
            v-if="(showNote || is_edit) && !is_view"
          >
            <b-col cols="6" class="mt-3">  
              <div>
                <b-form-textarea
                  id="textarea"
                  v-model="note"
                  placeholder="Enter something..."
                  rows="2"
                  max-rows="2"
                ></b-form-textarea>
              </div>
            </b-col>
          </b-row>
          <b-row
            class="d-flex flex-row align-items-start justify-content-start w-100 mt-5"
            no-gutters
            v-if="is_view"
          >
            <b-col cols="12" class="mt-3 p-3" style="background: #6fbeaf;">  
             <p style="font-weight: bold;" class="mb-2">Added Notes</p>
             <p>{{ note }}</p>
            </b-col>
          </b-row>
          <b-row v-if="is_edit || is_view"
            class="d-flex flex-row align-items-start justify-content-start w-100 mt-5"
            no-gutters
          >
            <b-col cols="12" class="mt-3 text-muted">  
            <em>{{ is_draft ? 'Draft' :'Manual Journal' }} Raised By {{ userName }} On {{raisedDate }} at {{ time }}</em>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-row>
    <template>
</template>
  </b-modal>
  
</template>
<script>
import FormInput from "@/components/Form/FormInput";
import FormSelect from "@/components/Form/FormSelect";
import { GetGlCode } from "@/services/items.service";
import { formatNumbers } from "@/util/formatNumbers.js";
import { GetCurrentWorkspace } from "@/services/settingsbusiness.service";
import {
  AddTransaction,
  GetExcludeCAB,
  UpdateTransaction,
} from "@/services/transactions.service";
import {
  createManualJournal,
  updateJournals,
} from "@/services/manualjournal.services";

export default {
  name: "AddTransactionModal",
  props: {
    journal: {
      type: Array,
      default: null,
    },
    view: {
      type: Boolean,
      default: false,
    },
    published:{
      type:Boolean,
      default:false
    },
  },
  data() {

    // const maxDate = new Date(today)
    // maxDate.setMonth(maxDate.getMonth() + 2)
    // maxDate.setDate(15)
    return {
      type:'income',
      raisedDate:null,
      isEditTransactionItem:false,
      createdDate:null,
      totalGstValue:0.00,
      minDate:null,
      publish_prop:false,
      is_view:false,
      note:null,
      showNote:false,
      glCodeList:[],
      transactionId: null,
      balance: true,
      creditDisable: false,
      debitDisable: false,
      accountType: null,
      transactionCategoryOptions: [],
      is_delete: false,
      idList: [],
      is_edit: false,
      is_draft: false,
      is_published: false,
      isRecurringJournal: false,
      isRecurringJournalEnd: false,
      taxRateOri: null,
      creditTotal: 0.0,
      debitTotal: 0.0,
      creditSubTotal: 0.0,
      debitSubTotal: 0.0,
      creditGstTotal: 0.0,
      debitGstTotal: 0.0,
      currency: localStorage.getItem("CURRENCY"),
      clearVal: false,
      transactions: [],
      itemId: null,
      description: null,
      taxRate: 0,
      debit: null,
      credit: null,
      totalAmount: null,
      glCode: null,
      referenceNumber: null,
      startDate: undefined,
      endDate: undefined,
      journalDescription: null,
      searchTable: null,
      isLoading: false,
      // selectStatus: null,
      glOptions: [],
      recurringOptions: ["Monthly", "Quarterly"],
      recurring: "Monthly",
      itemDescriptionError: {
        status: null,
        message: "",
      },
      glCodeError: {
        status: null,
        message: "",
      },
      creditError: {
        status: null,
        message: "",
      },
      debitError: {
        status: null,
        message: "",
      },
      taxRateError: {
        status: null,
        message: "",
      },
      referenceNumberError: {
        status: null,
        message: "",
      },
      createdDateError: {
        status: null,
        message: "",
      },
      startDateError: {
        status: null,
        message: "",
      },
      endDateError: {
        status: null,
        message: "",
      },
      journalDescriptionError: {
        status: null,
        message: "",
      },
      JournalHeader: [
        {
          key: "description",
          label: "Description",
          sortable: true,
        },
        {
          key: "glCode",
          label: "GL Code",
          sortable: true,
        },
        {
          key: "taxRate",
          label: "Tax Rate",
        },
        {
          key: "debit",
          label: "Debit",
        },
        {
          key: "credit",
          label: "Credit",
        },
        {
          key: "delete",
          label: "Delete",
          class: "text-right",
        },
        {
          key: "edit",
          label: "Edit",
          class: "text-right",
        },
      ],
    };
  },
  components: {
    FormInput,
    FormSelect,
  },
  watch: {
    published(val){
      this.publish_prop=val
    },
    view(val){
     this.is_view=val
    },
    description(val) {
      if (val) {
        if (val) {
          this.itemDescriptionError.status = true;
          if (val.length >= 50) {
            this.itemDescriptionError.status = false;
            this.itemDescriptionError.message =
              "Item description should be less than 50 characters";
          }
        }
        // else {
        //   this.itemDescriptionError.status = false;
        //   this.itemDescriptionError.message =
        //     "fill in the transaction description";
        // }
      }
    },
    glCode(val) {
      if (val) {
        if (val) {
          this.glCodeError.status = true;
        } else {
          this.glCodeError.status = false;
          this.glCodeError.message = "fill in the GL Code";
        }
      }
    },
    taxRate(val) {
      if (val) {
        if (val) {
          this.taxRateError.status = true;
        }
        // else {
        //   this.taxRateError.status = false;
        //   this.taxRateError.message = "fill in the tax rate";
        // }
      }
    },
    credit(val) {
      if (val) {
        if (val) {
          this.creditError.status = true;
          this.debitDisable = true;
        }
        // else {
        //   this.creditError.status = false;
        //   this.creditError.message = "fill in the credit value";

        // }
      } else {
        console.log("Null");
        this.credit = null;
        this.creditError.status = null;
        this.debitDisable = false;
      }
    },
    debit(val) {
      if (val) {
        if (val) {
          this.debitError.status = true;
          this.creditDisable = true;
        }
        // else {
        //   this.debitError.status = false;
        //   this.debitError.message = "fill in the debit value";

        // }
      } else {
        this.debit = null;
        this.creditDisable = false;
        this.debitError.status = null;
      }
    },
    transactions(val) {
      this.creditSubTotal= this.calculateSubAmount(val, "credit");
      this.debitSubTotal = this.calculateSubAmount(val, "debit");
      this.creditGstTotal= this.calculateGstAmount(val,"credit");
      this.debitGstTotal = this.calculateGstAmount(val, "debit");
      this.totalGstValue = this.creditGstTotal + this.debitGstTotal 
      this.creditTotal =   Number(this.creditSubTotal) + Number(this.totalGstValue)
      this.debitTotal =  Number(this.debitSubTotal) + (Number(this.totalGstValue))
      if (this.creditSubTotal !== this.debitSubTotal) {
        this.balance = false;
      } else {
        this.balance = true;
      }
    },

    referenceNumber(val) {
      if (val) {
        if (val) {
          this.referenceNumberError.status = true;
        }
      } else {
        this.referenceNumberError.status = false;
        this.referenceNumberError.message = "fill in the reference number";
      }
    },
    journalDescription(val) {
      if (val) {
        this.description=val
        if (val) {
          this.journalDescriptionError.status = true;
        }
      } else {
        this.journalDescriptionError.status = false;
        this.journalDescriptionError.message =
          "fill in the journal description";
      }
    },
    startDate(val) {
      if (val) {
        if (val) {
          this.startDateError.status = true;
        }
      } else {
        this.startDateError.status = false;
        this.startDateError.message = "select a start date";
      }
    },
    createdDate(val){
      if (val) {
        if (val) {
          this.createdDateError.status = true;
        }
      } else {
        this.createdDateError.status = false;
        this.createdDateError.message = "select a date";
      }
    },
    endDate(val) {
      if (val) {
        if (val) {
          this.endDateError.status = true;
        }
      } else {
        this.endDateError.status = false;
        this.endDateError.message = "select a end date";
      }
    },
    journal(val) {
      if (val) {
        this.editFn();
        if (!this.is_view) {
          this.is_edit = true;
        }
      }
    },
  },

  async mounted() {
    this.setDate()
    await this.initFn();
    await this.initCurrentWS();
    await this.getExclusiveDb();
  },
  methods: {
    setDate(){
      const now = new Date()
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      const minDate = new Date(today)
      minDate.setMonth(minDate.getMonth())
      minDate.setDate(today.getDate())
      this.minDate = minDate
    },
    async getExclusiveDb() {
      let { data } = await GetExcludeCAB();
      this.buildCategory(data);
    },
    cancel() {
      this.clearInputValues();
      this.clearTransactionItemValues();
      this.is_edit = false;
      this.publish_prop=false
    },
    closeModal() {
      this.clearInputValues();
      this.clearTransactionItemValues();
      this.is_edit = false;
      this.is_view = false;
      this.publish_prop=false

      this.$emit("closeFn");
    },
    async initCurrentWS() {
      let { data } = await GetCurrentWorkspace();
      this.taxRate = 0;
      this.taxRateOri = data.vat_rate;
    },
    calculateSubAmount(val, type) {
      let subTotal = val.reduce(function(total, item) {
        return total + Number(item[type]);
      }, 0);
      return subTotal
    },
    calTotalGstAmount(val) {
      let subTotal = val.reduce(function(total, item) {
        return total + Number(item.taxRate);
      }, 0);
      return subTotal
    },
    calculateGstAmount(val, type) {
      let subTotal = val.reduce(function(total, item) {
        return total + (Number(item[type]) * Number(item.taxRate)) / 100;
      }, 0);
      return subTotal
    },
    async initFn() {
      try {
        let { data } = await GetGlCode();
        this.glCodeList = data;
      } catch (error) {
        console.log(error);
      }
    },
    editFn() {
      this.raisedDate =this.dateFormat(this.journal[0].created_at,true)
      this.userName = this.journal[0]?.creator_name ?? ''
      this.journalDescription = this.journal[0].description;
      this.referenceNumber = this.journal[0].reference_number;
      this.startDate = this.journal[0].start_date;
      this.endDate = this.journal[0].end_date;
      this.recurring = this.journal[0].month;
      this.isRecurringJournal = this.journal[0].recurring_journal;
      this.isRecurringJournalEnd = this.journal[0].end_recurring;
      this.transactions = this.journal[0].transactions;
      this.transactionId = this.journal[0].transactionId;
      this.is_draft = this.journal[0].is_draft;
      this.is_published = this.journal[0].is_published;
      this.note = this.journal[0].note
      this.showNote = this.journal[0]?.note ? true:false
      this.createdDate = this.journal[0].createdDate
      // if(this.transaction[0]?.glCode){
      //   this.findCategory(this.transactions[0]);
      // }
      console.log(this.transactions[0]?.glCode)
      this.transactions[0]?.glCode ? this.findCategory(this.transactions[0]) :''
    },
    findGlCode(glCode){
      const type = "-"+glCode.account
      const glValue= this.glCodeList.find(function(gl){
       const code = gl.replace(/[0-9]/g, '');
       if(code===type){
        return gl
       }
      })
      return glValue
    },
      
    addSingleTransaction() {
      if (this.checkValidTransaction()) {
        const selectedIndex =this.selectedIndex
        const singleTransaction = {
          _id: undefined,
          description: this.description,
          glCode: this.findGlCode(this.glCode),
          taxRate: Number(this.taxRate),
          debit: Number(this.debit),
          credit: Number(this.credit),
          glCodeFullInfo:this.glCode
        };
        if(this.isEditTransactionItem){
          // this.transactions.find((_,i)=>{
          //   if(i!==this.selectedIndex){
          //    this.transactions.splice(+this.selectedIndex)
          //   }
          // })
       const result=   this.transactions.filter(function(item,i){
          if(i!== Number(selectedIndex)){
            return item
          }
         })
         this.transactions=result
        }
        const type = this.isEditTransactionItem ? 'updated' :'add'
        this.transactions.push(singleTransaction);
        this.findCategory(this.transactions[0]);
        this.clearTransactionItemValues();
        let payloadNotify = {
          isToast: true,
          title: "Manual Journal",
          content: `Transaction ${type} successfully`,
          variant: "success",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      }
    },
    removeItem(data) {
      this.is_delete = true;
      this.idList.push(data.item._id);
      this.transactions = this.transactions.filter(function(transaction, i) {
        if (data.index != i) {
          return transaction;
        }
      });
      let payloadNotify = {
        isToast: true,
        title: "Manual Journal",
        content: "Transaction delete successfully",
        variant: "success",
      };
      this.$store.dispatch("notification/setNotify", payloadNotify);
    },
    editItem(data){
      let {description,credit,debit,taxRate,glCodeFullInfo} = data.item
      if(this.is_edit){
        glCodeFullInfo= this.findCategory(this.transactions[data.index])
      }
      this.description = description
      this.glCode=glCodeFullInfo
      this.taxRate=taxRate,
      this.credit=credit,
      this.debit=debit
      this.isEditTransactionItem=true
      this.selectedIndex=data.index
    },
    async createJournal() {
      if( this.is_draft && !this.publish_prop) return this.markAsDraft()
      if (
        this.journalDescription &&
        this.referenceNumber &&
        this.createdDate &&
        // this.startDate &&
        // this.endDate &&
        this.balance &&
        ( this.isRecurringJournal ? this.startDate:true)  &&
        ( this.isRecurringJournalEnd ? this.endDate:true)  &&
        !this.transactions.length < 1
      ) {
        this.isLoading=true
        if (this.is_edit) {
          let res
          if( this.is_draft && this.publish_prop) {
            res = await AddTransaction(transactionPayload);
          }
          console.log(this.publish_prop ? !this.is_draft:false)
          const payload = {
            id: this.journal[0]._id,
            is_delete: this.is_delete,
            id_list: this.idList,
            reference_number: this.referenceNumber,
            start_date: this.startDate,
            end_date: this.endDate,
            month: this.recurring,
            description: this.journalDescription,
            recurring_journal: this.isRecurringJournal,
            end_recurring: this.isRecurringJournalEnd,
            transactions: this.transactions,
            transactionId:this.is_draft ? res.data.transaction[0]._id: this.transactionId,
            is_draft: this.publish_prop ? !this.is_draft:false,
            is_published: this.is_draft ? true: this.is_published,
            total_amount:Number(this.creditTotal),
            note:this.note,
            subTotal:this.creditSubTotal,
            totalGstAmount:this.totalGstValue,
            createdDate:this.createdDate
          };

          const transactionUpdate = {
            id: this.transactionId,
            date: this.startDate,
            total_amount: Number(this.creditTotal)*2,
            account: `${this.accountType.type},${this.accountType.subcategory},${this.accountType.account}`,
            category: `${this.accountType.type},${this.accountType.subcategory},${this.accountType.account}`,
            account_id: null,
            description: this.journalDescription,
            reference: this.referenceNumber,
            type:this.type
          };
    
          try {
            if(!this.is_draft){
              await UpdateTransaction(transactionUpdate);
            }
            const { data } = await updateJournals(this.journal[0]._id, payload);
            if (data.msg === "Updated successfully") {
              let payloadNotify = {
                isToast: true,
                title: "Manual Journal",
                content: "Manual Journal updated successfully",
                variant: "success",
              };

              this.$store.dispatch("notification/setNotify", payloadNotify);
            }
            this.clearInputValues();
            this.$emit("reloadFn");
            this.is_edit = false;
          } catch (error) {
            let payloadNotify = {
            isToast: true,
            title: "Manual Journal",
            content:error.response.data.msg,
            variant: "danger",
            };

            this.$store.dispatch("notification/setNotify", payloadNotify);
            console.log(error);
          }

          this.isLoading=false
          return;
        }
        this.is_published = true;
        const transactionPayload = {
          date: this.startDate ? this.startDate : Date.now(),
          total_amount: Number(this.creditTotal)*2,
          account: `${this.accountType.type},${this.accountType.subcategory},${this.accountType.account}`,
          category: `${this.accountType.type},${this.accountType.subcategory},${this.accountType.account}`,
          account_id: null,
          description: this.journalDescription,
          reference: this.referenceNumber,
          source:'Manual Journal',
          type:this.type
        };
        const res = await AddTransaction(transactionPayload);
        const payload = {
          reference_number: this.referenceNumber,
          start_date: this.startDate,
          end_date: this.endDate,
          month: this.recurring,
          description: this.journalDescription,
          recurring_journal: this.isRecurringJournal,
          end_recurring: this.isRecurringJournalEnd,
          transactions: this.transactions,
          transactionId: res.data.transaction[0]._id,
          is_draft: this.is_draft,
          is_published: this.is_published,
          total_amount:Number(this.creditTotal),
          ori_journal:true,
          note:this.note,
          subTotal:this.creditSubTotal,
          totalGstAmount:this.totalGstValue,
          createdDate:this.createdDate

        };
        try {
        const {data}= await createManualJournal(payload);
        console.log(res)
        if (data.msg === "Journal successfully created") {
          let payloadNotify = {
            isToast: true,
            title: "Manual Journal",
            content: "Manual Journal created successfully",
            variant: "success",
          };

          this.$store.dispatch("notification/setNotify", payloadNotify);
        } else {
          let payloadNotify = {
            isToast: true,
            title: "Manual Journal",
            content: "Something went wrong please try agin letter.",
            variant: "danger",
          };

          this.$store.dispatch("notification/setNotify", payloadNotify);
        }
        this.isLoading=false
        this.clearInputValues();
        this.$emit("reloadFn");
        } catch (error) {
          let payloadNotify = {
            isToast: true,
            title: "Manual Journal",
            content:error.response.data.msg,
            variant: "danger",
          };

          this.$store.dispatch("notification/setNotify", payloadNotify);
        }finally{
          this.isLoading=false
        }
      } else {
        const errorList = this.errorFn("journal");
        let payloadNotify = {
          isToast: true,
          title: "Manual Journal",
          content: "Error: Unable to create journal",
          list: errorList,
          variant: "danger",
        };

        this.$store.dispatch("notification/setNotify", payloadNotify);
      }
    },
    async markAsDraft() {
      this.isLoading=true
      if (this.is_edit) {
        const payload = {
          id: this.journal[0]._id,
          is_delete: this.is_delete,
          id_list: this.idList,
          reference_number: this.referenceNumber,
          start_date: this.startDate,
          end_date: this.endDate,
          month: this.recurring,
          description: this.journalDescription,
          recurring_journal: this.isRecurringJournal,
          end_recurring: this.isRecurringJournalEnd,
          transactions: this.transactions,
          is_draft: this.is_draft,
          is_published: this.is_published,
          note:this.note,
          createdDate:this.createdDate
        };
        try {
          const { data } = await updateJournals(this.journal[0]._id, payload);
          if (data.msg === "Updated successfully") {
            let payloadNotify = {
              isToast: true,
              title: "Manual Journal",
              content: "Draft updated successfully",
              variant: "success",
            };

            this.$store.dispatch("notification/setNotify", payloadNotify);
          }
          this.isLoading=false
        } catch (error) {
          console.log(error);
          this.isLoading=false
        }
        this.clearInputValues();
        this.$emit("reloadFn");
        this.is_edit = false;
        this.isLoading=false
        return;
      }
      this.is_draft = true;
      const payload = {
        reference_number: this.referenceNumber,
        start_date: this.startDate,
        end_date: this.endDate,
        month: this.recurring,
        description: this.journalDescription,
        recurring_journal: this.isRecurringJournal,
        end_recurring: this.isRecurringJournalEnd,
        transactions: this.transactions,
        is_draft: this.is_draft,
        is_published: this.is_published,
        createdDate:this.createdDate,
        note:this.note,
      };
      try {
            const { data } = await createManualJournal(payload)
            if (data.msg === "Journal successfully created") {
              let payloadNotify = {
                isToast: true,
                title: "Manual Journal",
                content: "Draft Saved Successfully",
                variant: "success",
              };

              this.$store.dispatch("notification/setNotify", payloadNotify);
              this.isLoading=false
            } else {
              let payloadNotify = {
                isToast: true,
                title: "Manual Journal",
                content: "Something went wrong please try agin letter.",
                variant: "danger",
              };

              this.$store.dispatch("notification/setNotify", payloadNotify);
            }
            this.isLoading=false
            this.clearInputValues();
            this.$emit("reloadFn");
      } catch (error) {
        console.log('Errors')
      }
    },
    clearTransactionItemValues() {
      this.description = this.journalDescription;
      this.itemDescriptionError.status = null;
      this.itemDescriptionError.message = "";
      this.glCode = null;
      this.glCodeError.status = null;
      this.glCodeError.message = "";
      this.taxRate = 0;
      this.taxRateError.status = null;
      this.taxRateError.message = "";
      this.debit = null;
      this.debitError.status = null;
      this.debitError.message = "";
      this.credit = null;
      this.creditError.status = null;
      this.creditError.message = "";
      this.isEditTransactionItem=false
      this.selectedIndex=null
    },
    clearInputValues() {
      this.journalDescription = null;
      this.journalDescriptionError.status = null;
      this.journalDescriptionError.message=""
      this.referenceNumber = null;
      this.referenceNumberError.status = null;
      this.referenceNumberError.message=""
      this.startDate = undefined;
      this.startDateError.status = null;
      this.endDate = undefined;
      this.endDateError.status = null;
      this.createdDateError.status=null,
      this.createdDateError.message=""
      this.recurring = "Monthly";
      this.isRecurringJournal = false;
      this.isRecurringJournalEnd = false;
      this.is_draft = false;
      this.is_published = false;
      this.transactions = [];
      this.creditTotal = 0.0;
      this.debitTotal = 0.0;
      this.creditSubTotal = 0.0;
      this.debitSubTotal = 0.0;
      this.creditGstTotal = 0.0;
      this.debitGstTotal = 0.0;
      this.is_delete = false;
      this.idList = [];
      this.accountType = null;
      this.note=null
      this.createdDate=null
      this.createdDateError.status = null;
      this.isLoading=false
      // this.publish_prop=false
    },
    checkValidTransaction() {
      const debitOrCredit = this.debit || this.credit;
      let taxValid = this.taxRate.length===0 ? false : true
      if (this.description && this.glCode && taxValid && debitOrCredit) {
        return true;
      } else {
        this.errorFn("transactions");
        return false;
      }
    },
    formatNumbers(val) {
      return formatNumbers(val);
    },
    formatDate(val) {
      if (val) {
        const dates = val.split("-");
        return `${dates[2]}/${dates[1]}/${dates[0]}`;
      }
    },

    getRecurringOption(val) {
      this.recurring = val;
    },

    dateFormat(date,time=false) {
      if (date) {
        const dateObj = new Date(date);
        let day = dateObj.getDate();
        let month = dateObj.getMonth()+1;
        const year = dateObj.getFullYear();
        if(time) this.getTime(date)
        if (day < 10) day = `0${day}`;
        if (month < 10) month = `0${month}`;
        return `${day}/${month}/${year}`
      }
    },
    getTime(date){
      const dateObj = new Date(date);
      let hour = dateObj.getHours()
      let minutes = dateObj.getMinutes()
      if (hour < 10) hour = `0${hour}`;
      if (minutes < 10) minutes = `0${minutes}`;
      const ampm = hour >= 12 ? 'PM' : 'AM';
      this.time =`${hour}:${minutes}.${ampm}`
      return `${hour}:${minutes}.${ampm}`
    },
    findCategory(data) {
      let val = [];
      const gl = data.glCode;
      for (let i = 0; i < this.transactionCategoryOptions.length; i++) {
        const item = this.transactionCategoryOptions[i].options;
        item.map((item) => {
          if (item.text === gl) {
            val.push(item);
            return item;
          }
        });
        if (val.length > 0) break;
      }
      this.accountType = val[0].value;
      const type = val[0].value.type
      switch(type){
        case 'Income':
          this.type='income'
          break
        case 'Expenses':
          this.type='expense'
          break
        default:
          this.type='income'    
      }
      return val[0].value
    },
    buildCategory(data) {
      this.accounts = data.chart.chart;
      let accountsArray = [];
      
      for (const account in this.accounts) {
        const options = [];
        let label
        for (const sub in this.accounts[account].sub) {
          label = this.accounts[account].title
          const labelW = this.accounts[account].sub[sub].title;
          if (labelW === "Cash and Bank") {
            continue;
          }
          for (const supersub in this.accounts[account].sub[sub].sub) {
            const value = {
              type: this.accounts[account].title,
              subcategory: this.accounts[account].sub[sub].title,
              account: this.accounts[account].sub[sub].sub[supersub].title,
            };
              //Fixed GLcode Undefined
            const glCode  = this.accounts[account].sub[sub].sub[supersub].gl_code
            if(glCode===undefined){
              continue
            }
            options.push({
              value,
              text:
                this.accounts[account].sub[sub].sub[supersub].gl_code +
                "-" +
                this.accounts[account].sub[sub].sub[supersub].title,
            });
          }
          
        }
        if (options.length > 0)
            accountsArray.push({
              label,
              options,
            });
      }
      this.transactionCategoryOptions = accountsArray;
      this.glOptions = accountsArray
    },
    errorFn(type) {
      let errorList = [];
      if (type === "transactions") {
        if (!this.description) {
          this.itemDescriptionError.status = false;
          this.itemDescriptionError.message =
            "Please enter description for transaction";
        }
        if (!this.glCode) {
          this.glCodeError.status = false;
          this.glCodeError.message = "Please select glCode from the list";
        }
        if (!this.taxRate) {
          this.taxRateError.status = false;
          this.taxRateError.message = "Please enter value for tax rate";
        }
        if (!this.debit) {
          this.debitError.status = false;
          this.debitError.message = "Please enter value for debit felid";
        }
        if (!this.credit) {
          this.creditError.status = false;
          this.creditError.message = "Please enter value for credit felid";
        }
        return;
      }
      if (type === "journal") {
        if (this.transactions < 1) {
          errorList.push("Please add one or more transaction");
        }
        if (!this.referenceNumber) {
          this.referenceNumberError.status = false;
          this.referenceNumberError.message =
            "Please enter a value for reference number";
          errorList.push(this.referenceNumberError.message);
        }
        
        if(this.isRecurringJournal){
          if (!this.startDate) {
          this.startDateError.status = false;
          this.startDateError.message = "Please select a start date";
          errorList.push(this.startDateError.message);
        }
        }
        if(this.isRecurringJournalEnd){
          if (!this.endDate) {
            this.endDateError.status = false;
            this.endDateError.message = "Please select a end date";
            errorList.push(this.endDateError.message);
          }
        }
        if (!this.journalDescription) {
          this.journalDescriptionError.status = false;
          this.journalDescriptionError.message =
            "Please description for journal";
          errorList.push(this.journalDescriptionError.message);
        }
        if (!this.balance) {
          errorList.push("Total credit must equal to total debit");
        }
        if(!this.createdDate){
          errorList.push("Please select a date");
          this.createdDateError.status = false;
          this.createdDateError.message = "Please select a date";
        }
      }

      return errorList;
    },
  },
};
</script>
<style scoped>
.text-red {
  color: #dc3545;
}
.delete-btn {
  background: none;
  border: none;
  color: transparent;
}
.delete-icon {
  color: #eb5757;
  font-size: 22px;
}
.types {
  width: 215px !important;
  margin: 0 20px !important;
}
::placeholder {
  color: #0b9b7d;
  opacity: 1;
}

.date-fields-style {
  border: 1.8px solid #0b9b7d;
}

.desWidth {
  width: 80%;
}

.count-style {
  border-radius: 30px;
  width: 30px;
  height: 30px;
  color: #0b9b7d;
  background-color: #f7fbfc;
  font-weight: 500;
  margin-top: -1px;
  padding-left: 10px;
  padding-top: 2px;
}

.disabled-button {
  background-color: #0b9b7d;
  background: rgba(33, 150, 83, 0.5);
  color: #ffffff;
  border-color: #ffffff;
}

.date-picker-btn >>> .btn .b-icon.bi {
  color: #0b9b7d;
}

.date-picker-btn {
  border: 1.8px solid;
  border-color: #0b9b7d;
  border-radius: 0px 3px 3px 0px;
}

.account-dropdown {
  max-height: 240px;
  overflow: auto;
  scrollbar-width: thin;
}

.account-dropdown::-webkit-scrollbar {
  width: 6px;
}

.account-dropdown::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(33, 150, 83, 0.5);
  border-radius: 5px;
}

.account-dropdown::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px #0b9b7d;
}

/* table card */
.table-card-row {
  height: 100%;
}

/* .table-card-row .type-filter {
  width: 100%;
} */
.table-card-row .table-container-card {
  background-color: var(--white);
  width: 100%;
  min-height: 400px;
  border-radius: 0;
  border: 1px solid var(--light);
}

/* table actions row */
.table-card-row .search-input-group {
  box-shadow: none;
  height: 50px;
  min-width: 200px;
}

.table-card-row .search-input-group .search-input {
  box-shadow: none;
  height: 50px;
}

.table-card-row .search-input-group .search-input::placeholder {
  color: #0b9b7d;
}

.table-card-row .category-dropdown,
.table-card-row .types-dropdown,
.table-card-row .accounts-dropdown,
.table-card-row .date-picker,
.table-card-row .add-question-btn {
  height: 45px;
  /* width: 100px; */
}

.table-card-row .add-question-btn:hover {
  background: #0b9b7d;
  box-shadow: inset 0px 0px 10px #ffffff;
  color: #ffffff;
  border: none;
}

.table-card-row .accounts-dropdown {
  min-width: 295px;
}

.table-card-row .category-dropdown {
  min-width: 310px;
  padding-left: 15px;
}

.table-card-row .types-dropdown {
  min-width: 310px;
  /* margin-right: 30px; */
}

.table-card-row .startdate-picker {
  width: 200px;
  border: 1.8px solid #0b9b7d;
  color: #0b9b7d;
}

.table-card-row .enddate-picker {
  width: 200px;
  border: 1.8px solid #0b9b7d;
}

/* date picker */
.table-card-row .b-form-datepicker.form-control:focus {
  color: #0b9b7d;
  background-color: #fff;
  border-color: #0b9b7d;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(33, 150, 83, 0.5);
}

/* date selector calendar dialog */
.table-card-row
  .b-form-datepicker
  >>> .custom-calendar-dropdown
  .b-calendar-grid.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #0b9b7d;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(33, 150, 83, 0.5);
}

.table-card-row .category-dropdown .types-dropdown >>> .filter-dropdown-menu {
  height: auto;
  min-width: 200px;
  max-height: 50vh;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
}

.table-card-row .types-dropdown >>> .filter-dropdown-menu {
  height: auto;
  min-width: 200px;
  max-height: 50vh;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
}

.table-card-row
  .filter-dropdown
  >>> .filter-dropdown-menu
  button.dropdown-item.active,
.table-card-row
  .filter-dropdown
  >>> .filter-dropdown-menu
  button.dropdown-item:active {
  background-color: var(--light);
}

/* table container */
.table-container-row {
  height: 100%;
  width: 50%;
  overflow-y: hidden;
  scrollbar-width: thin;
}

.table-card-row .apply-filters-btn {
  transition: color 200ms ease-in;
  /* color: var(--secondary); */
  /* border: none; */
}

.table-card-row .apply-filters-btn:hover {
  background-color: #0b9b7d;
  /* box-shadow: inset 0px 0px 10px #ffffff; */
  /* color: var(--primary); */
}

.clearAll-text-color {
  color: #0b9b7d;
}

.clearAll-text-color:hover {
  color: #0b9b7d;
}

.vertical-bar {
  margin-right: 19px;
  margin-left: 5px;
  margin-top: 2px;
  color: #bdbdbd;
}

.table-container-row .load-more-btn {
  transition: color 200ms ease-in;
  color: #0b9b7d;
}

.table-container-row .load-more-btn:hover {
  color: #0b9b7d;
  text-decoration: none;
}

/* .table-container-row .table-action-row {
  height: 60px;
  padding-top: 20px;
} */
/* scrollbar size fix for webkit browsers (chrome/safari) */
.table-container-row::-webkit-scrollbar {
  height: 0.5rem;
  scrollbar-width: thin;
}

.table-container-row::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(33, 150, 83, 0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f0f0f0;
}

.table-container-row::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
}

.table-container-row .table-element {
  min-width: 500px;
  width: 100%;
  height: auto;
  scrollbar-width: thin;
}

/* table element css */
.table-container-row .table-element >>> .table-header {
  /* background-color: #f7f7ff; */
  background-color: transparent;
}

.table-container-row .table-element >>> .table-header th {
  color: var(--prime-gray);
  font-size: 0.8rem;
  font-weight: 400;
  border-color: transparent;
}

/* .table-element >>> .table-body td {
  vertical-align: middle;
  
} */

.creditDebit-table .table-body td,
.creditDebit-table .table-body th {
  padding: 0px;
}
.table th,
.table td {
  padding: 0;
}
.table td,
.table th {
  padding: 0;
}
/* .table-element >>> .table-body tr:nth-child(even) {
  vertical-align: middle;
  background-color: var(--light);
} */
.table-element >>> .table-body .main-text {
  font-size: 0.9rem;
}

.table-element >>> .table-body .sub-text {
  font-size: 0.7rem;
}

/* question row */
.table-element >>> .table-body .question-row {
  max-width: 550px;
}

/* actions row */
.table-element >>> .table-body .actions-row {
  min-width: 140px;
}

/* more icon button (default state) */
.table-element >>> .table-body .more-btn {
  width: 35px;
  height: 35px;
  padding: 7px;
  background-color: #0b9b7d;
  transition: background-color 200ms ease-in;
}

.table-element >>> .table-body .more-btn > .more-icon {
  color: var(--white);
  transition: color 200ms ease-in;
}

/* more icon button (hover state) */
.table-element >>> .table-body .more-btn:hover {
  background-color: var(--prime-gray);
}

.table-element >>> .table-body .more-btn:hover > .more-icon {
  color: var(--white);
}

/* table question action buttons */
/* edit icon button (default state) */
.table-element >>> .table-body .edit-btn {
  width: 40px;
  height: 40px;
  padding: 7px;
  border: hidden;
  /* background-color: var(--white); */
  background-color: transparent;
  transition: background-color 200ms ease-in;
}

.table-element >>> .table-body .edit-btn > .edit-icon {
  color: #0b9b7d;
  transition: color 200ms ease-in;
}

.table-element >>> .table-body .edit-btn > .reviewed-icon {
  color: var(--success);
  transition: color 200ms ease-in;
}

/* edit icon button (hover state) */
.table-element >>> .table-body .edit-btn:hover {
  /* background-color: var(--info); */
}

.table-element >>> .table-body .edit-btn:hover > .edit-icon {
  color: #0b9b7d;
}

.table-element >>> .table-body .b-table-details {
  background-color: #fff;
}

.table-element >>> .table-body .b-table-details:hover {
  cursor: auto;
  background-color: #fff;
}

@media (max-width: 1346px) and (min-width: 1170px) {
  .category-field {
    max-width: 350px;
  }

  .type-field {
    max-width: 200px;
  }
}

@media (max-width: 1170px) and (min-width: 1118px) {
  .category-field {
    max-width: 330px;
  }

  .type-field {
    max-width: 200px;
  }
}

@media (max-width: 1118px) and (min-width: 1060px) {
  .category-field {
    max-width: 310px;
  }

  .type-field {
    max-width: 188px;
  }
}

@media (max-width: 1060px) and (min-width: 992px) {
  .category-field {
    max-width: 270px;
  }

  .type-field {
    max-width: 160px;
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .table-card-row .search-input-group {
    max-width: none;
  }

  .table-card-row .category-dropdown {
    min-width: 330px;
  }

  .table-card-row .types-dropdown {
    min-width: 330px;
  }
}</style
>>
